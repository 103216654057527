/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Rubik", sans-serif;
  --font-weight: 400;
  --content-width: 119rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;
  --clr-primary: #4ee436;
  --clr-bg: #0b0c10;
}

body {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--clr-default-100);
  background-color: var(--clr-bg);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.text--gray {
  color: rgba(255, 255, 255, 0.48);
}

.text--center {
  text-align: center;
}

.list {
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  color: rgba(255, 255, 255, 0.48);
}

.list li {
  position: relative;
  padding-left: 1.5rem;
}

.list li::before {
  content: "";
  position: absolute;
  left: 0.6rem;
  top: 0.7rem;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: rgba(255, 255, 255, 0.48);
}

.list li + li {
  margin-top: 0.8rem;
}

.title {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: clamp(
    2.6rem,
    2.1636363636rem + 1.3636363636vw,
    3.8rem
  );
  line-height: 120%;
}

.title--small {
  font-size: clamp(
    2rem,
    1.8545454545rem + 0.4545454545vw,
    2.4rem
  );
}

.pt190 {
  padding-top: 19rem;
}

.mt15 {
  margin-top: 1.5rem;
}

.pb190 {
  padding-bottom: 19rem;
}

.check .content {
  margin-top: 5rem;
}

.check .content table td:first-child {
  min-width: 45rem;
}

.summary__text {
  margin-top: 2rem;
}

.license {
  padding-top: 13rem;
  padding-bottom: 10rem;
  background-color: rgba(255, 255, 255, 0.04);
}

.deposit__text p + p {
  margin-top: 1.5rem;
}

.deposit__text + .list {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.live__img {
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 121.6rem;
}

.table-games {
  padding-bottom: 19rem;
  background-image: image-set(url("../img/table/bg@1x.jpg") 1x, url("../img/table/bg@2x.jpg") 2x);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.table-games__info {
  max-width: 60rem;
}

.content {
  overflow: auto;
  margin-top: 3rem;
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-radius: 3rem;
  padding: 5rem 3rem;
  background-color: rgba(36, 36, 36, 0.24);
  backdrop-filter: blur(80px);
}

.table {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
  width: 100%;
  min-width: 80rem;
  line-height: 150%;
}

.table tr {
  position: relative;
}

.table tr::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  border-radius: 1.5rem;
  width: calc(100% - 6rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

.table tr:last-child::before {
  visibility: hidden;
}

.table tr td:first-child {
  color: #808082;
}

.table tr td,
.table tr th {
  vertical-align: top;
  padding: 2rem;
}

.canada {
  padding-bottom: 13rem;
  background-color: #151619;
}

.menu {
  margin-left: auto;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item + .menu__item {
  margin-left: 3rem;
}

.menu__link {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-default-100);
}

.menu__link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.2rem;
  width: 0;
  height: 0.15rem;
  background-color: var(--clr-default-100);
  transform: translateX(-50%);
  transition: width var(--transition);
}

.logo {
  margin-right: 1.5rem;
  max-width: 17rem;
}

.burger {
  display: none;
}

.header {
  position: absolute;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  width: 100%;
}

.header__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2.5rem;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.08);
}

.header__btn {
  margin-left: 5rem;
  border: 2px solid var(--clr-primary);
  min-width: 20rem;
  min-height: 5rem;
  max-width: 22rem;
  line-height: 70%;
  color: var(--clr-primary);
  transition: background-color var(--transition), color var(--transition);
}

.footer {
  padding-bottom: 5rem;
}

.footer__img {
  margin-bottom: 2rem;
  max-width: 17rem;
}

.footer__text p + p {
  margin-top: 1.5rem;
}

.faq__title {
  margin-bottom: 2.5rem;
}

.faq__item {
  border-radius: 1.2rem;
  padding-right: 3rem;
  padding-left: 3rem;
  background-color: rgba(255, 255, 255, 0.12);
  transition: padding-bottom var(--transition);
}

.faq__item-title {
  position: relative;
  padding-top: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.7272727273rem + 0.2272727273vw,
    2rem
  );
  line-height: 130%;
  cursor: pointer;
}

.faq__item-title::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 2.2rem;
  height: 1.2rem;
  background-image: url("../img/ui/arrow.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%) rotate(0);
  transition: transform var(--transition);
}

.faq__item-body {
  overflow: hidden;
  max-height: 0;
  box-sizing: content-box;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.48);
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.faq__item:not(:last-child) {
  margin-bottom: 1rem;
}

.faq__item--active {
  transition: padding-bottom var(--transition);
}

.faq__item--active .faq__item-title::before {
  transform: translateY(-50%) rotate(180deg);
  transition: transform var(--transition);
}

.faq__item--active .faq__item-body {
  padding-bottom: 3rem;
  transition: max-height var(--transition), padding-bottom var(--transition);
}

.hero {
  padding-top: 17rem;
  background-image: linear-gradient(0deg, #0b0c10 0%, rgba(11, 12, 16, 0) 100%), url("../img/hero/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__title {
  font-size: clamp(
    3.4rem,
    2.6727272727rem + 2.2727272727vw,
    5.4rem
  );
}

.hero__title span {
  color: var(--clr-primary);
}

.hero__text p + p {
  margin-top: 1.5rem;
}

.hero__img {
  margin-right: 6.6rem;
  width: 100%;
  max-width: 55.9rem;
}

.hero__info {
  width: 100%;
  max-width: 60rem;
}

.hero__btn {
  margin-top: 5rem;
  min-width: 33rem;
  min-height: 7rem;
  max-width: 38rem;
  box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #37a425;
  transition: box-shadow var(--transition);
}

.hero__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.sign {
  background-image: image-set(url("../img/sign/bg@1x.png") 1x, url("../img/sign/bg@2x.png") 2x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sign__info {
  margin-left: auto;
  max-width: 70rem;
}

.sign__title {
  margin-bottom: 2rem;
  font-size: clamp(
    4rem,
    2.8363636364rem + 3.6363636364vw,
    7.2rem
  );
  text-transform: uppercase;
}

.sign__subtitle {
  font-weight: 400;
  font-size: clamp(
    2.6rem,
    2.1636363636rem + 1.3636363636vw,
    3.8rem
  );
}

.sign__btn {
  margin-top: 5rem;
  min-width: 24rem;
  min-height: 7rem;
  max-width: 26rem;
  box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #fa7600;
  transition: box-shadow var(--transition);
}

.sign__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.pros__img {
  margin-top: 3rem;
  margin-bottom: 5rem;
  max-width: 121.6rem;
}

.pros__img img {
  border-radius: 1rem;
}

.pros__wrapper {
  display: flex;
  justify-content: space-between;
}

.pros__list {
  flex: 0 0 47%;
}

.pros__item {
  display: flex;
  line-height: 150%;
}

.pros__item-img {
  margin-right: 1.5rem;
  width: 100%;
  max-width: 2.4rem;
}

.pros__item + .pros__item {
  margin-top: 2rem;
}

.welcome__img {
  margin-top: 3rem;
  margin-bottom: 5rem;
  max-width: 121.6rem;
}

.registration {
  padding-top: 13rem;
  background-color: #151619;
}

.registration__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registration__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -9.5rem;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.08);
}

.registration__img {
  margin-right: 6.6rem;
  width: 100%;
  max-width: 41.9rem;
}

.registration__info {
  width: 100%;
  max-width: 70rem;
}

.registration__list {
  counter-reset: section;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.registration__list li {
  padding-left: 2.5rem;
}

.registration__list li::before {
  display: none;
}

.registration__list li::after {
  counter-increment: section;
  /* Increment the value of section counter by 1 */
  content: counter(section) ". ";
  position: absolute;
  left: 0.6rem;
  top: 0;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  color: rgba(255, 255, 255, 0.48);
}

.registration__btn {
  margin-top: 5rem;
  min-width: 33rem;
  min-height: 7rem;
  max-width: 36rem;
  box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #37a425;
  transition: box-shadow var(--transition);
}

.registration__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.slot__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slot__info {
  width: 100%;
  max-width: 50rem;
}

.slot__list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.slot__img {
  margin-left: 3rem;
  max-width: 69.6rem;
}

.slot__img img {
  border-radius: 1rem;
}

.slot__btn {
  margin-top: 5rem;
  min-width: 30rem;
  min-height: 7rem;
  max-width: 32rem;
  box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #fa7600;
  transition: box-shadow var(--transition);
}

.slot__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.slot .title--small {
  margin-top: 6rem;
}

.win {
  background-image: image-set(url("../img/win/bg@1x.jpg") 1x, url("../img/win/bg@2x.jpg") 2x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.win__info {
  max-width: 70rem;
}

.win__title {
  margin-bottom: 2rem;
  font-size: clamp(
    3rem,
    2.5636363636rem + 1.3636363636vw,
    4.2rem
  );
  text-transform: uppercase;
}

.win__subtitle {
  font-weight: 400;
  font-size: clamp(
    2.6rem,
    2.1636363636rem + 1.3636363636vw,
    3.8rem
  );
}

.win__btn {
  margin-top: 5rem;
  min-width: 24rem;
  min-height: 7rem;
  max-width: 26rem;
  box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #37a425;
  transition: box-shadow var(--transition);
}

.win__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.software__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.software__list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.software__info {
  width: 100%;
  max-width: 60rem;
}

.software__img {
  margin-right: 3rem;
  width: 100%;
  max-width: 59.2rem;
}

.software__btn {
  margin-top: 5rem;
  min-width: 40rem;
  min-height: 7rem;
  max-width: 42rem;
  box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #fa7600;
  transition: box-shadow var(--transition);
}

.software__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

.mobile__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__info {
  margin-right: 1.5rem;
  width: 100%;
  max-width: 67rem;
}

.mobile__img {
  width: 100%;
  max-width: 57.6rem;
}

.mobile__text p + p {
  margin-top: 1.5rem;
}

.vpn {
  padding-top: 10rem;
  background-color: rgba(255, 255, 255, 0.04);
}

.vpn__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.vpn__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6.5rem;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.08);
}

.vpn__img {
  margin-right: 6.5rem;
  width: 100%;
  max-width: 17.6rem;
}

.support__text {
  margin-top: 1.5rem;
}

.support__list {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.support__item {
  display: flex;
  flex: 0 0 49%;
  border-radius: 1.2rem;
  padding: 3rem;
  background-color: #151619;
}

.support__item-img {
  margin-right: 2.5rem;
  width: 100%;
  max-width: 6.4rem;
}

.support__item-img img {
  border-radius: 1rem;
}

.support__item-title {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.7272727273rem + 0.2272727273vw,
    2rem
  );
  line-height: 150%;
}

.money {
  background-image: image-set(url("../img/money/bg@1x.png") 1x, url("../img/money/bg@2x.png") 2x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.money__info {
  margin-left: auto;
  max-width: 50rem;
}

.money__title {
  margin-bottom: 2rem;
  font-size: clamp(
    3rem,
    2.5636363636rem + 1.3636363636vw,
    4.2rem
  );
  text-transform: uppercase;
}

.money__subtitle {
  font-weight: 400;
  font-size: clamp(
    2.6rem,
    2.1636363636rem + 1.3636363636vw,
    3.8rem
  );
}

.money__btn {
  margin-top: 5rem;
  min-width: 24rem;
  min-height: 7rem;
  max-width: 26rem;
  box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  color: var(--clr-default-100);
  background-color: #fa7600;
  transition: box-shadow var(--transition);
}

.money__btn:active {
  box-shadow: initial;
  transition: box-shadow var(--transition);
}

@media (min-width: 48em) {
  .slot__list li + li {
    margin-top: initial;
  }

  .software__list li + li {
    margin-top: initial;
  }
}

@media (max-width: 75em) {
  .check .content table td:first-child {
    min-width: 35rem;
  }

  .content {
    padding: 2rem;
  }

  .hero__img {
    margin-right: 2.5rem;
  }

  .registration__img {
    margin-right: 2.5rem;
  }

  .software__img {
    margin-right: 1.5rem;
  }

  .vpn__img {
    margin-right: 4rem;
  }

  .support__item-img {
    margin-right: 1rem;
  }
}

@media (max-width: 62em) {
  .check .content table td:first-child {
    min-width: 25rem;
  }

  .table-games {
    padding-bottom: initial;
    background-image: initial;
  }

  .table-games__info {
    max-width: initial;
  }

  .content {
    padding: 1rem;
  }

  .table tr::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  .table tr td,
  .table tr th {
    padding: 1.5rem;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item + .menu__item {
    margin-top: 3rem;
    margin-left: initial;
  }

  .menu__link {
    font-size: clamp(
    2rem,
    1.8181818182rem + 0.5681818182vw,
    2.5rem
  );
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__btn {
    margin-left: initial;
  }

  .sign__info {
    max-width: 40rem;
  }

  .pros__wrapper {
    flex-direction: column;
  }

  .pros__list {
    flex: initial;
  }

  .pros__list + .pros__list {
    margin-top: 2rem;
  }

  .slot__wrapper {
    flex-direction: column;
  }

  .slot__info {
    max-width: initial;
  }

  .slot__img {
    margin-top: 3rem;
    margin-left: initial;
  }

  .win__info {
    max-width: 40rem;
  }

  .vpn__img {
    margin-right: 2.5rem;
  }

  .support__item {
    padding: 1.5rem;
  }

  .money__info {
    max-width: 40rem;
  }
}

@media (max-width: 48em) {
  .check .content table td:first-child {
    min-width: 20rem;
  }
}

@media (max-width: 36em) {
  .btn {
    min-width: initial;
  }

  .slot__list {
    display: block;
  }

  .slot__btn {
    margin-top: 3rem;
    min-width: initial;
    max-width: initial;
  }

  .software__list {
    display: block;
  }

  .software__btn {
    margin-top: 3rem;
    min-width: initial;
    max-width: initial;
  }

  .support__item-img {
    max-width: 4.4rem;
  }
}

@media (max-width: 78.125em) {
  .table-games {
    background-size: cover;
  }
}

@media (max-width: 47.9375em) {
  .pt190 {
    padding-top: 9.5rem;
  }

  .pb190 {
    padding-bottom: 9.5rem;
  }

  .check .content {
    margin-top: 3rem;
  }

  .license {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }

  .canada {
    padding-bottom: 6rem;
  }

  .header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .header__wrapper::before {
    bottom: -1.5rem;
  }

  .faq__item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .faq__item-title::before {
    width: 1.4rem;
    height: 0.8rem;
  }

  .hero {
    padding-top: 12rem;
  }

  .hero__wrapper {
    flex-direction: column-reverse;
  }

  .hero__img {
    margin-top: 3rem;
    margin-right: initial;
  }

  .hero__info {
    max-width: initial;
  }

  .hero__btn {
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
  }

  .sign {
    padding-bottom: initial;
    background-image: initial;
  }

  .sign__info {
    margin-left: initial;
    max-width: initial;
  }

  .sign__title,
  .sign__subtitle {
    text-align: center;
  }

  .sign__btn {
    margin-right: auto;
    margin-left: auto;
  }

  .registration {
    padding-top: 6rem;
  }

  .registration__wrapper {
    flex-direction: column-reverse;
  }

  .registration__wrapper::before {
    bottom: -4.5rem;
  }

  .registration__img {
    margin-top: 3rem;
    margin-right: initial;
  }

  .registration__info {
    max-width: initial;
  }

  .registration__btn {
    margin-right: auto;
    margin-left: auto;
  }

  .win__info {
    max-width: initial;
  }

  .win__title,
  .win__subtitle {
    text-align: center;
  }

  .win__btn {
    margin-right: auto;
    margin-left: auto;
  }

  .software__wrapper {
    flex-direction: column-reverse;
  }

  .software__info {
    max-width: initial;
  }

  .software__img {
    margin-top: 2.5rem;
    margin-right: initial;
  }

  .mobile__wrapper {
    flex-direction: column;
  }

  .mobile__info {
    margin-right: initial;
    margin-bottom: 2.5rem;
    max-width: initial;
  }

  .vpn {
    padding-top: 5rem;
  }

  .vpn__wrapper {
    flex-direction: column-reverse;
  }

  .vpn__wrapper::before {
    bottom: -3rem;
  }

  .vpn__img {
    margin-top: 2.5rem;
    margin-right: initial;
  }

  .support__list {
    display: block;
    margin-top: 2.5rem;
  }

  .support__item + .support__item {
    margin-top: 1.5rem;
  }

  .money {
    padding-top: initial;
    padding-bottom: initial;
    background-image: initial;
  }

  .money__info {
    margin-left: initial;
    max-width: initial;
  }

  .money__title,
  .money__subtitle {
    text-align: center;
  }

  .money__btn {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (hover) {
  .menu__link:hover::before {
    width: 100%;
    transition: width var(--transition);
  }

  .header__btn:hover {
    color: var(--clr-bg);
    background-color: var(--clr-primary);
    transition: background-color var(--transition), color var(--transition);
  }

  .hero__btn:hover {
    box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    transition: box-shadow var(--transition);
  }

  .sign__btn:hover {
    box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    transition: box-shadow var(--transition);
  }

  .registration__btn:hover {
    box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    transition: box-shadow var(--transition);
  }

  .slot__btn:hover {
    box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    transition: box-shadow var(--transition);
  }

  .win__btn:hover {
    box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    transition: box-shadow var(--transition);
  }

  .software__btn:hover {
    box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    transition: box-shadow var(--transition);
  }

  .money__btn:hover {
    box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    transition: box-shadow var(--transition);
  }
}