.support {
  &__text {
    margin-top: 1.5rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;

    @media (max-width: em(767)) {
      display: block;
      margin-top: 2.5rem;
    }
  }

  &__item {
    display: flex;
    flex: 0 0 49%;
    border-radius: 1.2rem;
    padding: 3rem;
    background-color: #151619;

    @media (max-width: em(992)) {
      padding: 1.5rem;
    }

    &-img {
      margin-right: 2.5rem;
      width: 100%;
      max-width: 6.4rem;

      @media (max-width: em(1200)) {
        margin-right: 1rem;
      }

      @media (max-width: em(576)) {
        max-width: 4.4rem;
      }

      img {
        border-radius: 1rem;
      }
    }

    &-title {
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: fluid-font(2rem, 1.8rem);
      line-height: 150%;
    }
  }

  &__item + &__item {
    @media (max-width: em(767)) {
      margin-top: 1.5rem;
    }
  }
}
