.faq {
  &__title {
    margin-bottom: 2.5rem;
  }

  &__item {
    border-radius: 1.2rem;
    padding-right: 3rem;
    padding-left: 3rem;
    background-color: rgba(255, 255, 255, 0.12);
    transition: padding-bottom var(--transition);

    @media (max-width: em(767)) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    &-title {
      position: relative;
      padding-top: 1.5rem;
      padding-right: 3rem;
      padding-bottom: 1.5rem;
      font-weight: 600;
      font-size: fluid-font(2rem, 1.8rem);
      line-height: 130%;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 2.2rem;
        height: 1.2rem;
        background-image: url("../img/ui/arrow.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%) rotate(0);
        transition: transform var(--transition);

        @media (max-width: em(767)) {
          width: 1.4rem;
          height: 0.8rem;
        }
      }
    }

    &-body {
      overflow: hidden;
      max-height: 0;
      box-sizing: content-box;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.48);
      transition: max-height var(--transition), padding-bottom var(--transition);
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &--active {
      transition: padding-bottom var(--transition);

      .faq__item-title {
        &::before {
          transform: translateY(-50%) rotate(180deg);
          transition: transform var(--transition);
        }
      }

      .faq__item-body {
        padding-bottom: 3rem;
        transition: max-height var(--transition), padding-bottom var(--transition);
      }
    }
  }
}
