.slot {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    width: 100%;
    max-width: 50rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: em(576)) {
      display: block;
    }

    li + li {
      @media (min-width: em(768)) {
        margin-top: initial;
      }
    }
  }

  &__img {
    margin-left: 3rem;
    max-width: 69.6rem;
    img {
      border-radius: 1rem;
    }

    @media (max-width: em(992)) {
      margin-top: 3rem;
      margin-left: initial;
    }
  }

  &__btn {
    margin-top: 5rem;
    min-width: 30rem;
    min-height: 7rem;
    max-width: 32rem;
    box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    font-size: fluid-font(2rem, 1.6rem);
    color: var(--clr-default-100);
    background-color: #fa7600;
    transition: box-shadow var(--transition);

    @media (max-width: em(576)) {
      margin-top: 3rem;
      min-width: initial;
      max-width: initial;
    }

    &:active {
      box-shadow: initial;
      transition: box-shadow var(--transition);
    }

    @media (hover) {
      &:hover {
        box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }

  .title--small {
    margin-top: 6rem;
  }
}
