.mobile {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    width: 100%;
    max-width: 67rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2.5rem;
      max-width: initial;
    }
  }

  &__img {
    width: 100%;
    max-width: 57.6rem;
  }

  &__text {
    p + p {
      margin-top: 1.5rem;
    }
  }
}
