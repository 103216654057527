.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: em(576)) {
    min-width: initial;
  }
}

.text {
  &--gray {
    color: rgba(255, 255, 255, 0.48);
  }

  &--center {
    text-align: center;
  }
}

.list {
  font-size: fluid-font(1.8rem, 1.6rem);
  color: rgba(255, 255, 255, 0.48);

  li {
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      left: 0.6rem;
      top: 0.7rem;
      border-radius: 50%;
      width: 0.3rem;
      height: 0.3rem;
      background-color: rgba(255, 255, 255, 0.48);
    }
  }

  li + li {
    margin-top: 0.8rem;
  }
}

.title {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: fluid-font(3.8rem, 2.6rem);
  line-height: 120%;

  &--small {
    font-size: fluid-font(2.4rem, 2rem);
  }
}

.pt190 {
  padding-top: 19rem;

  @media (max-width: em(767)) {
    padding-top: 9.5rem;
  }
}

.mt15 {
  margin-top: 1.5rem;
}

.pb190 {
  padding-bottom: 19rem;

  @media (max-width: em(767)) {
    padding-bottom: 9.5rem;
  }
}

.check {
  .content {
    margin-top: 5rem;

    @media (max-width: em(767)) {
      margin-top: 3rem;
    }

    table {
      td {
        &:first-child {
          min-width: 45rem;

          @media (max-width: em(1200)) {
            min-width: 35rem;
          }

          @media (max-width: em(992)) {
            min-width: 25rem;
          }

          @media (max-width: em(768)) {
            min-width: 20rem;
          }
        }
      }
    }
  }
}

.summary {
  &__text {
    margin-top: 2rem;
  }
}

.license {
  padding-top: 13rem;
  padding-bottom: 10rem;
  background-color: rgba(255, 255, 255, 0.04);

  @media (max-width: em(767)) {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}

.deposit {
  &__text {
    p + p {
      margin-top: 1.5rem;
    }
  }

  &__text + .list {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.live {
  &__img {
    margin-top: 5rem;
    margin-bottom: 3rem;
    max-width: 121.6rem;
  }
}

.table-games {
  padding-bottom: 19rem;
  background-image: image-set(url("../img/table/bg@1x.jpg") 1x, url("../img/table/bg@2x.jpg") 2x);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: em(1250)) {
    background-size: cover;
  }

  @media (max-width: em(992)) {
    padding-bottom: initial;
    background-image: initial;
  }

  &__info {
    max-width: 60rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }
}

.content {
  overflow: auto;
  margin-top: 3rem;
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-radius: 3rem;
  padding: 5rem 3rem;
  background-color: rgba(36, 36, 36, 0.24);
  backdrop-filter: blur(80px);

  @media (max-width: em(1200)) {
    padding: 2rem;
  }

  @media (max-width: em(992)) {
    padding: 1rem;
  }
}

.table {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
  width: 100%;
  min-width: 80rem;
  line-height: 150%;

  tr {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 2rem;
      bottom: 0;
      border-radius: 1.5rem;
      width: calc(100% - 6rem);
      height: 0.1rem;
      background-color: rgba(255, 255, 255, 0.11);

      @media (max-width: em(992)) {
        left: 1.5rem;
        width: calc(100% - 3rem);
      }
    }

    &:last-child {
      &::before {
        visibility: hidden;
      }
    }
    td:first-child {
      color: #808082;
    }

    td,
    th {
      vertical-align: top;
      padding: 2rem;

      @media (max-width: em(992)) {
        padding: 1.5rem;
      }
    }
  }
}

.canada {
  padding-bottom: 13rem;
  background-color: #151619;

  @media (max-width: em(767)) {
    padding-bottom: 6rem;
  }
}
