.pros {
  &__img {
    margin-top: 3rem;
    margin-bottom: 5rem;
    max-width: 121.6rem;

    img {
      border-radius: 1rem;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__list {
    flex: 0 0 47%;

    @media (max-width: em(992)) {
      flex: initial;
    }
  }

  &__list + &__list {
    @media (max-width: em(992)) {
      margin-top: 2rem;
    }
  }

  &__item {
    display: flex;
    line-height: 150%;

    &-img {
      margin-right: 1.5rem;
      width: 100%;
      max-width: 2.4rem;
    }
  }

  &__item + &__item {
    margin-top: 2rem;
  }
}
