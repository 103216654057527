.header {
  position: absolute;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  width: 100%;

  @media (max-width: em(767)) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2.5rem;
      width: 100%;
      height: 0.2rem;
      background-color: rgba(255, 255, 255, 0.08);

      @media (max-width: em(767)) {
        bottom: -1.5rem;
      }
    }
  }

  &__btn {
    margin-left: 5rem;
    border: 2px solid var(--clr-primary);
    min-width: 20rem;
    min-height: 5rem;
    max-width: 22rem;
    line-height: 70%;
    color: var(--clr-primary);
    transition: background-color var(--transition), color var(--transition);

    @media (hover) {
      &:hover {
        color: var(--clr-bg);
        background-color: var(--clr-primary);
        transition: background-color var(--transition), color var(--transition);
      }
    }

    @media (max-width: em(992)) {
      margin-left: initial;
    }
  }
}
