.vpn {
  padding-top: 10rem;
  background-color: rgba(255, 255, 255, 0.04);

  @media (max-width: em(767)) {
    padding-top: 5rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: em(767)) {
      flex-direction: column-reverse;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -6.5rem;
      width: 100%;
      height: 0.2rem;
      background-color: rgba(255, 255, 255, 0.08);

      @media (max-width: em(767)) {
        bottom: -3rem;
      }
    }
  }

  &__img {
    margin-right: 6.5rem;
    width: 100%;
    max-width: 17.6rem;

    @media (max-width: em(1200)) {
      margin-right: 4rem;
    }

    @media (max-width: em(992)) {
      margin-right: 2.5rem;
    }

    @media (max-width: em(767)) {
      margin-top: 2.5rem;
      margin-right: initial;
    }
  }
}
