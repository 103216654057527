.money {
  background-image: image-set(url("../img/money/bg@1x.png") 1x, url("../img/money/bg@2x.png") 2x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: em(767)) {
    padding-top: initial;
    padding-bottom: initial;
    background-image: initial;
  }

  &__info {
    margin-left: auto;
    max-width: 50rem;

    @media (max-width: em(992)) {
      max-width: 40rem;
    }

    @media (max-width: em(767)) {
      margin-left: initial;
      max-width: initial;
    }
  }

  &__title,
  &__subtitle {
    @media (max-width: em(767)) {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: fluid-font(4.2rem, 3rem);
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 400;
    font-size: fluid-font(3.8rem, 2.6rem);
  }

  &__btn {
    margin-top: 5rem;
    min-width: 24rem;
    min-height: 7rem;
    max-width: 26rem;
    box-shadow: 0px 5px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
    font-size: fluid-font(2rem, 1.6rem);
    color: var(--clr-default-100);
    background-color: #fa7600;
    transition: box-shadow var(--transition);

    @media (max-width: em(767)) {
      margin-right: auto;
      margin-left: auto;
    }

    &:active {
      box-shadow: initial;
      transition: box-shadow var(--transition);
    }

    @media (hover) {
      &:hover {
        box-shadow: 0px 2px 0px #af5300, 0px 14px 48px rgba(175, 83, 0, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }
}
