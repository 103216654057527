$burgerMQ: em(992);

.menu {
  margin-left: auto;

  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__item + &__item {
    margin-left: 3rem;

    @media (max-width: $burgerMQ) {
      margin-top: 3rem;
      margin-left: initial;
    }
  }

  &__link {
    position: relative;
    display: block;
    font-weight: 500;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 150%;
    color: var(--clr-default-100);

    @media (max-width: $burgerMQ) {
      font-size: fluid-font(2.5rem, 2rem);
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.2rem;
      width: 0;
      height: 0.15rem;
      background-color: var(--clr-default-100);
      transform: translateX(-50%);
      transition: width var(--transition);
    }

    @media (hover) {
      &:hover {
        &::before {
          width: 100%;
          transition: width var(--transition);
        }
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
