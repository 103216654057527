.footer {
  padding-bottom: 5rem;

  &__img {
    margin-bottom: 2rem;
    max-width: 17rem;
  }

  &__text {
    p + p {
      margin-top: 1.5rem;
    }
  }
}
