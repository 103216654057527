.hero {
  padding-top: 17rem;
  background-image: linear-gradient(0deg, #0b0c10 0%, rgba(11, 12, 16, 0) 100%), url("../img/hero/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: em(767)) {
    padding-top: 12rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column-reverse;
    }
  }

  &__title {
    font-size: fluid-font(5.4rem, 3.4rem);

    span {
      color: var(--clr-primary);
    }
  }

  &__text {
    p + p {
      margin-top: 1.5rem;
    }
  }

  &__img {
    margin-right: 6.6rem;
    width: 100%;
    max-width: 55.9rem;

    @media (max-width: em(1200)) {
      margin-right: 2.5rem;
    }

    @media (max-width: em(767)) {
      margin-top: 3rem;
      margin-right: initial;
    }
  }

  &__info {
    width: 100%;
    max-width: 60rem;

    @media (max-width: em(767)) {
      max-width: initial;
    }
  }

  &__btn {
    margin-top: 5rem;
    min-width: 33rem;
    min-height: 7rem;
    max-width: 38rem;
    box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    font-size: fluid-font(2rem, 1.6rem);
    color: var(--clr-default-100);
    background-color: #37a425;
    transition: box-shadow var(--transition);

    @media (max-width: em(767)) {
      margin-top: 3rem;
      margin-right: auto;
      margin-left: auto;
    }

    &:active {
      box-shadow: initial;
      transition: box-shadow var(--transition);
    }

    @media (hover) {
      &:hover {
        box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }
}
