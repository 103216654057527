.registration {
  padding-top: 13rem;
  background-color: #151619;

  @media (max-width: em(767)) {
    padding-top: 6rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column-reverse;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -9.5rem;
      width: 100%;
      height: 0.2rem;
      background-color: rgba(255, 255, 255, 0.08);

      @media (max-width: em(767)) {
        bottom: -4.5rem;
      }
    }
  }

  &__img {
    margin-right: 6.6rem;
    width: 100%;
    max-width: 41.9rem;

    @media (max-width: em(1200)) {
      margin-right: 2.5rem;
    }

    @media (max-width: em(767)) {
      margin-top: 3rem;
      margin-right: initial;
    }
  }

  &__info {
    width: 100%;
    max-width: 70rem;

    @media (max-width: em(767)) {
      max-width: initial;
    }
  }

  &__list {
    counter-reset: section;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    li {
      padding-left: 2.5rem;
      &::before {
        display: none;
      }

      &::after {
        counter-increment: section; /* Increment the value of section counter by 1 */
        content: counter(section) ". ";
        position: absolute;
        left: 0.6rem;
        top: 0;
        font-size: fluid-font(1.8rem, 1.6rem);
        color: rgba(255, 255, 255, 0.48);
      }
    }
  }

  &__btn {
    margin-top: 5rem;
    min-width: 33rem;
    min-height: 7rem;
    max-width: 36rem;
    box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    font-size: fluid-font(2rem, 1.6rem);
    color: var(--clr-default-100);
    background-color: #37a425;
    transition: box-shadow var(--transition);

    @media (max-width: em(767)) {
      margin-right: auto;
      margin-left: auto;
    }

    &:active {
      box-shadow: initial;
      transition: box-shadow var(--transition);
    }

    @media (hover) {
      &:hover {
        box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }
}
