.win {
  background-image: image-set(url("../img/win/bg@1x.jpg") 1x, url("../img/win/bg@2x.jpg") 2x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__info {
    max-width: 70rem;

    @media (max-width: em(992)) {
      max-width: 40rem;
    }

    @media (max-width: em(767)) {
      max-width: initial;
    }
  }

  &__title,
  &__subtitle {
    @media (max-width: em(767)) {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: fluid-font(4.2rem, 3rem);
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 400;
    font-size: fluid-font(3.8rem, 2.6rem);
  }

  &__btn {
    margin-top: 5rem;
    min-width: 24rem;
    min-height: 7rem;
    max-width: 26rem;
    box-shadow: 0px 5px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
    font-size: fluid-font(2rem, 1.6rem);
    color: var(--clr-default-100);
    background-color: #37a425;
    transition: box-shadow var(--transition);

    @media (max-width: em(767)) {
      margin-right: auto;
      margin-left: auto;
    }

    &:active {
      box-shadow: initial;
      transition: box-shadow var(--transition);
    }

    @media (hover) {
      &:hover {
        box-shadow: 0px 2px 0px #205e15, 0px 14px 48px rgba(55, 164, 37, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }
}
